/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { ApiEntity } from '@backstage/catalog-model';
import { useEntity } from '@backstage/plugin-catalog-react';
import Alert from '@material-ui/lab/Alert';
import React, { useEffect, useState } from 'react';
import './styles.css';
import { CardTab, TabbedCard } from '@backstage/core-components';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { PlainApiDefinitionWidget, apiDocsConfigRef } from '@backstage/plugin-api-docs';
import CopyButton from "./CopyDocLink"


/** @public */
export const ApiCardEnvironments = () => {
  const { entity } = useEntity<ApiEntity>();
  const config = useApi(apiDocsConfigRef);
  const { getApiDefinitionWidget } = config;
  const configRef = useApi(configApiRef)
  const backstageURL = configRef.getString('backend.baseUrl')

  if (!entity) {
    return <Alert severity="error">Could not fetch the API</Alert>;
  }

  if(entity.metadata.annotations?.doc == undefined || entity.metadata.annotations?.type == undefined){
    return <Alert severity="error">MISSING KEY entity.metadata.annotations.doc OR entity.metadata.annotations?.type IN ENTITY</Alert>;
  }

  const definitionWidget = getApiDefinitionWidget(entity);
  const entityTitle = entity.metadata.title ?? entity.metadata.name;
  const docType = entity.metadata.annotations?.type

  const [selectedEnvironment, setSelectedEnvironment] = useState('prod')
  const [openApiContent, setOpenApiContent] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      if(docType == "openapi"){
        const response = await fetch(`${backstageURL}/api/proxy/apisdocs/${selectedEnvironment}/openapi-schemes/${entity.metadata.annotations?.doc}?cache=${new Date().getTime()}`)
        setOpenApiContent(await response.json())
      }
    }
    fetchData()
  }, [selectedEnvironment])


  if(docType != "apidoc"){
    const openapiURL = `https://roit-apisdoc.s3-sa-east-1.amazonaws.com/redoc/index.html?url=https://roit-apisdoc.s3-sa-east-1.amazonaws.com/${selectedEnvironment}/openapi-schemes/${entity.metadata.annotations?.doc}?cache=${new Date().getTime()}&theme=light`
    return (
      
      <TabbedCard title={entityTitle}>
  
        <CardTab label="redoc" key="widget">
          <link rel="stylesheet" href="styles.css" />
          <h1 className="environment">env</h1>
          <select
            value={selectedEnvironment} // ...force the select's value to match the state variable...
            onChange={e => setSelectedEnvironment(e.target.value)} // ... and update the state variable on any change!
            className='selectEnvironment'
          >
            <option value="prod">prod</option>
            <option value="hom">hom</option>
            <option value="sandbox">sandbox</option>
          </select>
          <br/>
          <br/>
          <CopyButton link={openapiURL} />
          <br/>
          <br/>
          {
            <iframe title={entity.metadata.name} src={openapiURL} style={{ height: '100vh', width: '100%', backgroundColor: 'white' }} />        
          }     
        </CardTab>

        <CardTab label="swagger" key="widget">
          <link rel="stylesheet" href="styles.css" />
          <h1 className="environment">env</h1>
          <select
            value={selectedEnvironment} // ...force the select's value to match the state variable...
            onChange={e => setSelectedEnvironment(e.target.value)} // ... and update the state variable on any change!
            className='selectEnvironment'
          >
            <option value="prod">prod</option>
            <option value="hom">hom</option>
            <option value="sandbox">sandbox</option>
          </select>
          <br/>
          <br/>
          {
            definitionWidget?.component(JSON.stringify(openApiContent))
          }     
        </CardTab>
  
        <CardTab label="raw" key="raw">
          <PlainApiDefinitionWidget
            definition={JSON.stringify(openApiContent)}
            language={definitionWidget?.rawLanguage || entity.spec.type}
          />
        </CardTab>

      </TabbedCard>
    );
  }else{
    const apidocURL = `https://roit-apisdoc.s3-sa-east-1.amazonaws.com/${selectedEnvironment}/doc/${entity.metadata.annotations?.doc}/index.html?cache=${new Date().getTime()}`
    return (
      <TabbedCard title={entityTitle}>
        <CardTab label={docType} key="widget">
          <link rel="stylesheet" href="styles.css" />
          <h1 className="environment">env</h1>
          <select
            value={selectedEnvironment} // ...force the select's value to match the state variable...
            onChange={e => setSelectedEnvironment(e.target.value)} // ... and update the state variable on any change!
            className='selectEnvironment'
          >
            <option value="prod">prod</option>
            <option value="hom">hom</option>
            <option value="sandbox">sandbox</option>
          </select>
          <br/>
          <br/>
          <CopyButton link={apidocURL} />
          <br/>
          <br/>

          {
            <iframe title={entity.metadata.name} src={apidocURL} style={{ height: '100vh', width: '100%'}} />        
          }     
        </CardTab>
        
        <CardTab label="raw" key="raw">
          <PlainApiDefinitionWidget
            definition="not contains"
            language="link"
          />
        </CardTab>
      </TabbedCard>
    );
  }
};
