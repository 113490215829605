import React, { useState, useEffect } from 'react';

interface CopyButtonProps {
  link: string;
}

const CopyButton: React.FC<CopyButtonProps> = ({ link }) => {
  const [buttonText, setButtonText] = useState('Copy Link');

  useEffect(() => {
    setButtonText('Copy Link');
  }, [link]);

  const handleCopy = () => {
    navigator.clipboard.writeText(link)
      .then(() => {
        setButtonText('Copied!');
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  };

  return (
    <button onClick={handleCopy} style={styles.button}>
      {buttonText}
    </button>
  );
};

const styles = {
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    transition: 'background-color 0.3s ease',
  }
};

export default CopyButton;