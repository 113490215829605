import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const iamCloudSolicitationsPlugin = createPlugin({
  id: 'iam-cloud-solicitations',
  routes: {
    root: rootRouteRef,
  },
});

export const IamCloudSolicitationsPage = iamCloudSolicitationsPlugin.provide(
  createRoutableExtension({
    name: 'IamCloudSolicitationsPage',
    component: () =>
      import('./IamCloudSolicitations').then(m => m.IamCloudSolicitationsComponent),
    mountPoint: rootRouteRef,
  }),
);
